<template>
	<div>
		<b-row class="mb-3">
			<b-col
				cols="12"
				class="d-flex">
				<b-button
					v-if="$can('media_library', 'create')"
					:to="{ name: 'NewMediaItem', query: $route.query}"
					variant="success"
					class="d-flex justify-content-center border-0 px-3 mr-3">
					{{ translate('create') }}
				</b-button>
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-form-select
					id="media-language"
					v-model="selectedLanguage"
					type="text"
					class="form-control ml-auto mr-4 w-20"
					@change="changeLanguage($event)">
					<template slot="first">
						<option
							v-for="language in availableLanguages"
							:key="language.text"
							:value="language.value">
							{{ translate(language.text.toLowerCase()) }}
						</option>
					</template>
				</b-form-select>
				<b-button
					:class="selectedView === 'list' ? 'bg-primary' : ''"
					class="d-flex justify-content-center border-0 p-2 mr-2"
					@click="handleView('list')">
					<img :src="require(`@/assets/images/common/library/list.svg`)">
				</b-button>
				<b-button
					:class="selectedView === 'card' ? 'bg-primary' : ''"
					class="d-flex justify-content-center border-0 p-2 mr-4"
					@click="handleView('card')">
					<img :src="require(`@/assets/images/common/library/card.svg`)">
				</b-button>
				<b-button
					v-if="$can('media_library', 'delete')"
					:disabled="checkedRows.length === 0 "
					class="d-flex justify-content-center border-0 align-items-center"
					variant="danger"
					@click="deleteMedia(checkedRows, '', true)">
					<img :src="require(`@/assets/images/common/library/trashcan.svg`)">
				</b-button>
			</b-col>
		</b-row>
		<b-row v-if="showFilters">
			<b-col
				:class="isAdminOrCorporateWithCountry ? 'col-12 col-xl-6' : 'col-12'">
				<b-form-group id="formSelector">
					<slot name="countries">
						<label>
							{{ translate('search') }}
						</label>
					</slot>
					<b-form-input
						id="search"
						ref="update_search"
						v-model="keyword"
						:placeholder="translate('keyword_placeholder')"
						name="search"
						type="text"
						@keyup.enter="filterByCountry" />
				</b-form-group>
			</b-col>
			<b-col
				v-if="isAdminOrCorporateWithCountry"
				class="col-12 col-xl-6">
				<b-form-group id="formSelector">
					<slot name="company_type">
						<label>
							{{ translate('company_type') }}
						</label>
					</slot>
					<multiselect
						id="companyTypes"
						v-model="selectorCompanies"
						:options="companyTypes"
						:multiple="true"
						:close-on-select="false"
						:clear-on-select="false"
						:preserve-search="true"
						:preselect-first="false"
						:placeholder="translate('select_company_type')"
						label="text"
						:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
						track-by="value"
						name="companyTypes">
						<template slot="selection" />
						<template #option="{option}">
							{{ option.text }}
						</template>
					</multiselect>
				</b-form-group>
			</b-col>
			<b-col
				v-if="isAdminOrCorporateWithCountry"
				class="col-12 col-xl-12">
				<b-form-group id="formSelector">
					<slot name="countries">
						<label>
							{{ translate('country') }}
						</label>
					</slot>
					<multiselect
						id="countries"
						v-model="selector"
						:options="countries"
						:multiple="true"
						:close-on-select="false"
						:clear-on-select="false"
						:preserve-search="true"
						:preselect-first="false"
						:placeholder="translate('country_placeholder')"
						label="text"
						:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
						track-by="value"
						name="countries">
						<template slot="selection" />
						<template #option="{option}">
							{{ option.text }}
						</template>
					</multiselect>
				</b-form-group>
			</b-col>
			<b-col class="mb-3">
				<input
					:value="translate('submit')"
					type="submit"
					class="btn bg-primary-alt btn-primary mr-1"
					@click="filterByCountry()">
				<b-button
					variant="secondary"
					@click="clearFilterByCountry()">
					{{ translate('clear') }}
				</b-button>
			</b-col>
		</b-row>
		<div
			id="media-grid"
			class="row animated fadeIn">
			<template v-if="!isLoading && hasMedia && selectedView === 'card'">
				<div class="col-12 px-1">
					<div
						v-for="(file, key) in mediaCard"
						:key="key"
						role="tablist"
						class="w-100 mb-0">
						<div
							class="col-sm-12 mb-4"
							role="tab">
							<b-button
								v-b-toggle="`accordion-${key}`"
								block
								href="#"
								class="collapse-btn-media border-0 bg-transparent text-left pb-0 px-0 d-inline-flex w-auto">
								<span class="when-opened mr-2">
									<i
										class="fas fa-chevron-up"
										aria-hidden="true" />
								</span>
								<span class="when-closed mr-2">
									<i
										class="fas fa-chevron-down"
										aria-hidden="true" />
								</span>
								<h5 class="mb-0">
									{{ file.category }}
								</h5>
							</b-button>
							<hr class="my-2">
						</div>
						<b-collapse
							:id="`accordion-${key}`"
							:accordion="($route.query.keyword === '' || $route.query.keyword === undefined) ? 'my-accordion' : ''"
							visible
							role="tabpanel">
							<b-row class="w-100 mx-auto">
								<template v-for="(mediaInfo, mediaKey) in file.media">
									<div
										:key="mediaKey"
										class="col-12 col-sm-3">
										<media-card
											v-model="checkedRows"
											:alt="mediaInfo.name"
											:file-title-short="mediaInfo.short_name"
											:file-title="mediaInfo.name"
											:start-date="mediaInfo.start_date"
											:end-date="mediaInfo.end_date"
											:is-admin="isAdminOrCorporate"
											:thumbnail="`${mediaInfo.url_thumbnail}?b=${new Date(mediaInfo.modified_at.date).getTime()}`"
											:companies="companiesText(mediaInfo.company_types)"
											:media-path="mediaInfo.url"
											:media-id="mediaInfo.id"
											:identifier="`card-${key}-${mediaKey}`"
											@event="deleteMedia" />
									</div>
								</template>
							</b-row>
						</b-collapse>
					</div>
				</div>
			</template>
			<template v-else-if="!isLoading && hasMedia && selectedView === 'list'">
				<div class="col-12 px-2">
					<div class="table-responsive">
						<table class="table table-hover media-list text-nowrap">
							<thead>
								<tr class="bg-transparent text-dark">
									<th class="border-0 pb-1 pt-3 px-2">
										<h6 class="font-weight-bold d-block d-sm-none mb-0" />
									</th>
									<th
										class="border-0 pb-1 pt-3 px-2">
										<h6 class="font-weight-bold mb-0">
											{{ translate('file_type') }}
										</h6>
									</th>
									<th
										class="border-0 pb-1 pt-3 px-1">
										<h6 class="font-weight-bold mb-0">
											{{ translate('name') }}
										</h6>
									</th>
									<th
										v-if="isAdminOrCorporate"
										class="border-0 pb-1 pt-3 px-1">
										<h6 class="font-weight-bold mb-0">
											{{ translate('short_name') }}
										</h6>
									</th>
									<th
										class="border-0 pointer pb-1 pt-3 px-1 d-none d-sm-table-cell"
										@click="sortByField('code_name')">
										<h6 class="font-weight-bold mb-0">
											{{ translate('category') }}
											<sort field="code_name" />
										</h6>
									</th>
									<th
										v-if="isAdminOrCorporate"
										class="border-0 pointer pb-1 pt-3 px-2 d-none d-sm-table-cell">
										<h6 class="font-weight-bold mb-0">
											{{ translate('company_type') }}
										</h6>
									</th>
									<th
										class="border-0 pointer pb-1 pt-3 px-2 d-none d-sm-table-cell"
										@click="sortByField('media_libraries.created_at')">
										<h6 class="font-weight-bold mb-0">
											{{ translate('date_modified') }}
											<sort field="media_libraries.created_at" />
										</h6>
									</th>
									<th
										v-if="isAdminOrCorporate"
										class="border-0 pointer pb-1 pt-3 px-2 d-none d-sm-table-cell"
										@click="sortByField('media_libraries.start_date')">
										<h6 class="font-weight-bold mb-0">
											{{ translate('start_date') }}
											<sort field="media_libraries.start_date" />
										</h6>
									</th>
									<th
										v-if="isAdminOrCorporate"
										class="border-0 pointer pb-1 pt-3 px-2 d-none d-sm-table-cell"
										@click="sortByField('media_libraries.end_date')">
										<h6 class="font-weight-bold mb-0">
											{{ translate('end_date') }}
											<sort field="media_libraries.end_date" />
										</h6>
									</th>
									<th
										class="border-0 pb-1 pt-3 px-2">
										<h6 class="font-weight-bold mb-0" />
									</th>
									<th
										class="border-0 pb-1 pt-3 px-2">
										<h6 class="font-weight-bold mb-0" />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(mediaInfo, mediaKey) in mediaList"
									:id="`row-${mediaKey}`"
									:key="mediaKey"
									:class="{ 'checked-row': checkedRows.includes(mediaInfo.id)}">
									<td class="align-middle py-1 px-2 check-field text-center">
										<input
											v-if="$can('media_library', 'delete')"
											v-model="checkedRows"
											:value="mediaInfo.id"
											type="checkbox">
									</td>
									<td class="align-middle p-1 text-left">
										<img :src="require(`@/assets/images/common/library/files/${mediaInfo.ext}_icon.svg`)">
									</td>
									<td class="align-middle p-1">
										{{ mediaInfo.name }}
									</td>
									<td
										v-if="isAdminOrCorporate"
										class="align-middle p-1">
										{{ mediaInfo.short_name }}
									</td>
									<td class="align-middle p-1 d-none d-sm-table-cell w-25">
										{{ mediaInfo.category }}
									</td>
									<td
										v-if="isAdminOrCorporate"
										class="align-middle p-1">
										{{ companiesText(mediaInfo.company_types) }}
									</td>
									<td class="align-middle p-1 px-2 d-none d-sm-table-cell text-left">
										{{ mediaInfo.date }}
									</td>
									<td
										v-if="isAdminOrCorporate"

										class="align-middle p-1 px-2 d-none d-sm-table-cell text-left">
										{{ $moment(mediaInfo.start_date.date).format('LLL') }}
									</td>
									<td
										v-if="isAdminOrCorporate"
										class="align-middle p-1 px-2 d-none d-sm-table-cell text-left">
										{{ mediaInfo.end_date ? $moment(mediaInfo.end_date.date).format('LLL') : '' }}
									</td>
									<td
										:class="windowWidth === 'xs'? '' : 'w-9' "
										class="align-middle py-1">
										<a
											v-if="mediaInfo.fileOption === `open`"
											:href="mediaInfo.url"
											:class="windowWidth === 'xs'? 'w-min bg-secondary' : 'w-9 bg-primary-alt' "
											target="_blank"
											class="btn btn-media-list py-2 text-white d-flex mx-auto">
											<img
												:src="require(`@/assets/images/common/library/open-bk.svg`)"
												class="d-flex d-sm-none">
											<span class="d-none d-sm-flex">{{ translate('open') }}</span>
										</a>
										<b-button
											v-else-if="mediaInfo.fileOption === `view`"
											v-b-modal="`modal-${mediaKey}`"
											:class="windowWidth === 'xs'? 'w-min bg-secondary' : 'w-9 bg-success-alt' "
											type="button"
											class="btn-media-list py-2 d-flex mx-auto pointer"
											variant="">
											<img
												:src="require(`@/assets/images/common/library/view-bk.svg`)"
												class="d-flex d-sm-none">
											<span class="d-none d-sm-flex">{{ translate('view') }}</span>
										</b-button>
									</td>
									<td
										v-if="$can('media_library', 'update') || $can('media_library', 'delete')"
										class="align-middle p-1 text-center mx-2">
										<a
											:id="`popover-${mediaKey}`"
											tabindex="0"
											role="button"
											class="text-center"
											variant="media-popover">
											<img
												:src="require(`@/assets/images/common/library/ellipsis.svg`)"
												class="list-ellipsis pointer pr-2">
										</a>
										<b-popover
											ref="popover"
											:target="`popover-${mediaKey}`"
											triggers="focus"
											placement="auto"
											class="z-0">
											<div class="popover-content">
												<div
													v-if="$can('media_library', 'update')"
													class="popover-row d-flex text-left mb-2">
													<b-button
														:to="`/media-library/update/${mediaInfo.id}`"
														variant="popover-option"
														class="p-0 d-flex align-items-center w-100">
														<img
															:src="require(`@/assets/images/common/library/edit.svg`)"
															class="mr-2 w-20">
														<span>{{ translate('edit') }}</span>
													</b-button>
												</div>
												<div
													v-if="$can('media_library', 'delete')"
													class="popover-row d-flex text-left">
													<b-button
														variant="popover-option"
														class="p-0 d-flex align-items-center w-100"
														@click="deleteMedia(mediaInfo.id, mediaInfo.name)">
														<img
															:src="require(`@/assets/images/common/library/trashcan.svg`)"
															class="dark-filter mr-2 w-17">
														<span>{{ translate('delete') }}</span>
													</b-button>
												</div>
											</div>
										</b-popover>
									</td>
									<b-modal
										v-if="mediaInfo.fileOption === `view`"
										:id="`modal-${mediaKey}`"
										centered
										modal-class="media-modal"
										hide-footer
										size="xl">
										<div class="text-center text-white">
											<img
												:src="mediaInfo.url"
												class="mb-2 h-auto mw-100">
											<h5 class="mt-2 font-weight-bold">
												{{ mediaInfo.name }}
											</h5>
										</div>
									</b-modal>
								</tr>
							</tbody>
						</table>
					</div>
					<b-pagination
						v-if="pagination.total_pages > 1"
						v-model="pagination.current_page"
						:total-rows="pagination.total"
						:per-page="pagination.per_page"
						align="center"
						class="mt-4"
						@change="getDataFiltered" />
				</div>
			</template>
		</div>
		<is-loading
			:loading="isLoading"
			:has-data="hasMedia"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			size="bg" />
	</div>
</template>

<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import MediaLibrary from '@/util/MediaLibrary';
import WindowSizes from '@/mixins/WindowSizes';
import Alert from '@/util/Alert';
import {
	IMAGES_EXT, AVAILABLE_IMG_EXT, LANGUAGES, COMPANY_TYPES as validCompanyTypes,
} from '@/settings/Media';
import {
	MediaLibrary as mediaTranslations,
	Languages,
	Validations,
	Countries as countriesTranslations,
} from '@/translations';
import { PAGINATION } from '@/settings/RequestReply';
import { superAdmin, corporate } from '@/settings/Roles';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import sort from '@/components/SortArrow';
import { MMMDY_FORMAT } from '@/settings/Dates';
import MediaCard from './components/MediaCard';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'MediaLibrary',
	messages: [
		mediaTranslations,
		Languages,
		Validations,
		countriesTranslations,
	],
	components: {
		MediaCard,
		sort,
		Multiselect,
	},
	mixins: [WindowSizes],
	data() {
		return {
			types: [],
			mediaLibrary: new MediaLibrary(),
			deleteMediaLibrary: new MediaLibrary(),
			selectedView: '',
			checkedRows: [],
			alert: new Alert(),
			selectedLanguage: process.env.VUE_APP_LANG,
			availableLanguages: [],
			validCountries,
			validCompanyTypes,
			superAdmin,
			corporate,
			showFilters: true,
			selector: [],
			selectorCompanies: [],
			countries: [],
			countriesOnly: [],
			companyTypes: [],
			companyTypesOnly: [],
			keyword: '',
		};
	},
	computed: {
		isLoading() {
			return !!this.mediaLibrary.data.loading;
		},
		errors() {
			try {
				return this.mediaLibrary.data.errors;
			} catch (error) {
				return [];
			}
		},
		media() {
			try {
				const { data } = this.mediaLibrary.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasMedia() {
			const response = this.media.length;
			return !!response;
		},
		mediaList() {
			const mediaFilesList = [];
			this.media.forEach((file) => {
				const fileComplete = file.attributes.url.split('/').pop();
				let ext = fileComplete.split('.').pop();
				if (!AVAILABLE_IMG_EXT.includes(ext)) ext = 'generic'; // default extension
				else if (ext === 'jpeg') ext = 'jpg';
				const fileOption = this.viewOrOpen(ext);
				const date = this.$moment(file.attributes.modified_at.date).format(MMMDY_FORMAT);
				mediaFilesList.push({
					id: file.id,
					name: file.attributes.name,
					short_name: file.attributes.short_name,
					start_date: file.attributes.start_date,
					end_date: file.attributes.end_date,
					category: this.translate(file.attributes.category_code),
					company_types: file.attributes.company_types,
					url: file.attributes.url,
					url_thumbnail: file.attributes.url_thumbnail,
					ext,
					date,
					fileOption,
				});
			});
			return mediaFilesList;
		},
		mediaCard() {
			const fileAttributes = [];
			const fileId = [];
			this.media.forEach((file) => {
				fileId.push({ id: file.id });
				fileAttributes.push(file.attributes);
			});
			for (let index = 0; index < fileAttributes.length; index += 1) {
				fileAttributes[index] = { ...fileId[index], ...fileAttributes[index] };
			}
			const filesByCategories = (_
				.chain(fileAttributes)
				.groupBy('category_code')
				.map((value, key) => ({ category: this.translate(key), media: value }))
				.value()
			);
			return filesByCategories;
		},
		pagination() {
			return this.mediaLibrary.data.pagination;
		},
		isAdminOrCorporateWithCountry() {
			return this.superAdmin.includes(this.$user.details().type) || (this.corporate.includes(this.$user.details().type) && !this.$user.details().country);
		},
		isAdminOrCorporate() {
			return this.superAdmin.includes(this.$user.details().type) || this.corporate.includes(this.$user.details().type);
		},
	},
	watch: {
		selector() {
			const select = this.selector;
			// no need to reassign 'select' if it's already equal to all of the countries
			if (this.handleSelectAll(select) === true && select !== this.countriesOnly) {
				this.selector = this.countriesOnly;
			}
		},
		selectorCompanies() {
			const select = this.selectorCompanies;
			// no need to reassign 'select' if it's already equal to all of the companies
			if (this.handleSelectAll(select) === true && select !== this.companyTypesOnly) {
				this.selectorCompanies = this.companyTypesOnly;
			}
		},
		language() {
			try {
				this.initializeMultiselect();
				if (this.selector.length > 0) {
					this.selector = this.selector.map((item) => ({
						value: item.value,
						text: this.translate(item.value.toLowerCase()),
					}));
				}
				return this.selector;
			} catch (error) {
				return this.selector;
			}
		},
	},
	created() {
		this.loadContent();
		this.availableLanguages = LANGUAGES.map((languageCode) => ({
			value: languageCode,
			text: `${languageCode}_language`,
		}));
	},
	mounted() {
		this.initializeView();
		if (this.isAdminOrCorporateWithCountry) this.initializeMultiselect();
	},
	methods: {
		initializeView() {
			this.selectedView = 'card';
			if (['xs', 'sm'].includes(this.windowWidth)) {
				this.selectedView = 'list';
			}
			const [urlRegexp, languageRegexp] = [/view_type=([^&]+)/, /language=([^&]+)/];
			const [url, language] = [window.location.href.match(urlRegexp), window.location.href.match(languageRegexp)];
			if (url !== null) {
				[this.selectedView] = [url[1]];
			}
			if (language !== null) {
				[this.selectedLanguage] = [language[1]];
			}
			this.$route.query.view_type = this.selectedView;
			this.$route.query.language = this.selectedLanguage;
		},
		changeLanguage(language) {
			this.$route.query.language = language;
			this.mediaLibrary.getMediaLibrary();
		},
		filterByCountry() {
			this.$route.query.country = this.getCountriesIso(this.selector);
			this.$route.query.companies = this.getTypes(this.selectorCompanies);
			this.$route.query.keyword = this.keyword;
			this.mediaLibrary.getMediaLibrary();
		},
		clearFilterByCountry() {
			this.selector = [];
			this.selectorCompanies = [];
			this.keyword = '';
			this.$route.query.country = '';
			this.$route.query.companies = '';
			this.$route.query.keyword = '';
			this.mediaLibrary.getMediaLibrary();
		},
		loadContent() {
			this.mediaLibrary.getMediaLibrary();
		},
		handleView(viewType) {
			this.selectedView = viewType;
			this.$route.query.view_type = viewType;
			this.mediaLibrary.getMediaLibrary();
		},
		viewOrOpen(extension) {
			let fileOption = 'open';
			if (IMAGES_EXT.includes(extension)) fileOption = 'view';
			return fileOption;
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.mediaLibrary.getMediaLibrary(options);
		},
		initializeMultiselect() {
			// Countries
			this.countries = this.validCountries.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.countriesOnly = [...this.countries];
			this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });

			// Company Types
			this.companyTypes = this.validCompanyTypes.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.companyTypesOnly = [...this.companyTypes];
			this.companyTypes.unshift({ value: 'ALL', text: this.translate('all_company_types') });
			this.form.company_types = this.searchSelectValues(this.form.company_types, this.companyTypesOnly);
		},
		handleSelectAll(select) {
			// 'this.countries' array can contain 'Select all option' object
			// 'this.countriesOnly' does not change and it never contains 'Select all option' object, only countries
			let allSelected = false;
			const selectedCountries = select.map((country) => country.value);
			const numberOfCountries = this.countriesOnly.length;
			const selectAllExists = (this.countries[0].value === 'ALL');
			// If:  1) 'select all' option is chosen		OR		2) if you chose all countries manually
			// delete 'select all' option (as all countries are already chosen)
			if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
				if (selectAllExists) {
					this.countries.shift();
				}
				allSelected = true;
			}
			// If 1) 'selected all' option is deleted		2) not all countries are selected
			// then insert 'select all' into the dropdown options.
			if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
			return allSelected;
		},
		getCountriesIso(select) {
			const selectedIsoCodes = select.map((countryAttributes) => countryAttributes.value).toString();
			return selectedIsoCodes;
		},
		getTypes(select) {
			const selectedUserTypes = select.map((userTypes) => {
				if (userTypes.value === 'ALL') {
					return this.userTypes.map((userType) => userType.value);
				}
				return userTypes.value;
			}).toString();
			return selectedUserTypes;
		},
		deleteMedia(id, text, multiple = false) {
			const mediaIds = {
				media_files: [id],
			};
			let successText = this.translate('swal_success_delete_text');
			let title = this.translate('swal_delete_title', { title: text });
			if (multiple === true) {
				mediaIds.media_files = id;
				successText = this.translate('swal_success_multiple_delete_text');
				title = this.translate('swal_multiple_delete_title');
			}
			const trans = {
				title,
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				mediaIds.password = password.value;
				this.deleteMediaLibrary.deleteMedia(mediaIds).then(() => {
					this.alert.toast('success', successText);
					this.loadContent();
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.deleteMediaLibrary.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.deleteMediaLibrary.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				}).finally(() => {
					this.checkedRows = [];
				});
			}).catch(() => null);
		},
		companiesText(companies) {
			if (companies === null) {
				return '';
			}
			if (companies === '') {
				return this.translate('all_companies_apply');
			}
			return companies.split(',').map((company) => this.translate(company.toLowerCase())).join(', ');
		},
	},
};
</script>
