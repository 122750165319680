import {
	GET_MEDIA as GET,
	GET_PUSH_NOTIFICATIONS_MEDIA,
	CREATE_MEDIA as CREATE,
	DELETE_MEDIA as DELETE,
	UPDATE_MEDIA as UPDATE,
	GET_RESOURCE,
	GET_MEDIA_CATEGORIES,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();
class MediaLibrary {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	getMediaLibrary(options) {
		const { method, endpoint } = GET;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPushMediaLibrary(queryParams) {
		const { method, endpoint } = GET_PUSH_NOTIFICATIONS_MEDIA;
		const params = Req.setFilters(queryParams);
		return this.data[method](endpoint(params)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createMedia(options) {
		const { endpoint, method } = CREATE;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = axios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	getResource(mediaId) {
		const { method, endpoint } = GET_RESOURCE;
		return this.data[method](endpoint(mediaId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateMedia(mediaId, options) {
		const { method } = UPDATE;
		const endpoint = UPDATE.endpoint(mediaId);
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	deleteMedia(options) {
		const { method, endpoint } = DELETE;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getMediaCategories() {
		const { method, endpoint } = GET_MEDIA_CATEGORIES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default MediaLibrary;
